/* ProductDetails.css */


.main-image {
    width: 100%;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    transition: border 0.3s ease;
}

.main-image:hover {
    border: 2px solid #555;
}

h3 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
}

.discounted-price {
    color: #e44d26;
    font-size: 18px;
}

.normal-price {
    font-size: 16px;
}

.strike-through {
    text-decoration: line-through;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.error {
    color: #ff0000;
    font-size: 18px;
}

.loading {
    font-size: 18px;
    font-style: italic;
}

/* Optional: Customize slider styles */
.slick-prev,
.slick-next {
    font-size: 24px;
    color: #333;
}

.slick-prev:hover,
.slick-next:hover {
    color: #007bff;
}

/* Optional: Add some spacing for recommended products */
.prod-card {
    margin-bottom: 20px;
}

.prod-card img {
    max-width: 100%;
    border-radius: 4px;
}

.prod-card-title {
    font-size: 18px;
    margin: 10px 0;
}

.prod-card-price {
    color: #e44d26;
    font-size: 16px;
}

.prod-card-price-discount {
    text-decoration: line-through;
    color: #666;
    font-size: 14px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}
.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.popup-buttons button {
    padding: 10px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
#root{
    width: 100%;
    overflow-x: hidden;
}
.popup-buttons button.continue-shopping {
    background-color: #4caf50;
    color: #fff;
}

.popup-buttons button.checkout {
    background-color: #007bff;
    color: #fff;
}

