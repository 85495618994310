/* src/Home.css */
.slider-container {
    position: relative;
    display: inline-block;
}

.slider-image {
    max-width: 100%;
    height: auto;
}

.slider-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.categories-container {
    margin-top: 20px;
}

.category-item {
    margin-bottom: 20px;
    position: relative;
}

.category-title {
    position: absolute;
    bottom: 45px;
    left: 15%;
    transform: translateX(-15%);
    max-width: 150px;
    font-size: 20px;
    color: white;
}
.slick-slider {
    margin-top: 20px;
}
.category-image {
    max-width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 100%);

    transition: opacity 0.3s ease;
}

/* Stil pentru containerul imaginilor din slider */
.slick-slider .slick-slide {
    position: relative;
    /* Adăugă această linie pentru a face overlay-ul vizibil */
}

/* Aplică overlay doar la hover */
.slick-slider .slick-slide:hover .overlay {
    opacity: 1;
}

.slick-list {
    padding: 0 20% 0 0 !important;
}

.swipe-guide {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    z-index: 3;
    bottom: 40%;
    animation: myfirst 3s 5;
    animation-delay: 1s;
}

.swipe-guide img {
    width: 100%;
}

.slick-slide img {
    display: block;
    -webkit-box-shadow: 0px 0px 2px 2px rgb(227 227 227);
    -moz-box-shadow: 0px 0px 2px 2px rgba(204, 194, 204, 1);
    box-shadow: 0px 0px 2px 2px rgb(239 239 239);
    padding: 5px;
    border-radius: 5px;
}

.sliderHomePage{
    padding: 0 !important;
}

.homeContainer{
    background-color: #f2f2f7;
}

.search-container{
    width: 100%;
    padding: 0px;
}
