.search-container {
    position: relative;
    
}

.search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #fff;
    padding-right: 30px;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 0px;
}

.recent-links{
    position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        margin-top: 0px;
}
.search_links{
    vertical-align: middle;
}
.search_links{
    width: 100%;
    display: inline-block;
    text-align: left;
    text-decoration: none;
    color: #000;
}
.product-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}
.search_links tr{
    width: 100%;
    display: inline-block;
    border: 0px;
}

/* Adauga sageata de back in dreapta la input */
.search-input::after {
    content: '←';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0 !important;
    margin: 0;
}

li{
    text-align: left;
}

.search-container {
    position: relative;
}

.recent-links{
    height: 100vh;
}
.search-results{
    height: 100vh;
}
.input-container {
    position: relative;
    display: flex;
    align-items: center;
}


.close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    transform: translateY(-50%);
    color: #888;
}

.close-button:hover {
    color: #333;
}

.search-container {
    position: relative;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-input {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 4px;
    outline: none;
        border: 0px !important;
}

.close-button,
.back-button {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    color: #888;
}

.close-button {
    right: 10px;
}

.back-button {
    left: 10px;
}

.close-button:hover,
.back-button:hover {
    color: #333;
}

.back-button {
    position: absolute;
    top: 50%;
    left: 10px;
    cursor: pointer;
    transform: translateY(-50%);
    color: #888;
    display: none;
}

.back-button:hover {
    color: #333;
}

.input-container:focus-within .back-button {
    display: block;
}

.back-button{
    border: 0px;
    background-color: transparent;
    padding: 0px;
    color: #000;
}