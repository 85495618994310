body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Hensa';
  src: url('assets/Hensa-Regular.woff2') format('woff2'),
    url('assets/Hensa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spritz And Delicious PERSONAL';
  src: url('assets/SpritzAndDeliciousPERSONAL-Regular.woff2') format('woff2'),
    url('assets/SpritzAndDeliciousPERSONAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination_cat{
  margin: auto;
}
.price_list, .regular_price_list {
  clear: both;
  padding: 0 0 5px;
  font-size: 1.25rem;
  position: relative;
  font-weight: 500;
}

.regular_price_list{
  color: #81d1e5;
}

.price_list {
  color: #81d1e5;
}

.view_product{
  width: 100%;
  border-color: #f30d77;
  font-size: 1.05rem !important;
  background-color: #f30d77;
  -webkit-box-shadow: -1px -2px 12px 0px #FFFFFF;
  box-shadow: -1px -2px 12px 0px #FFFFFF;
}

.card-href{
  color: black !important;
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #fff;
  position: sticky !important;
  overflow-y: scroll;
  overflow-x: hidden;
  bottom: -1px;
  width: 98%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar a {
  float: left;
  display: block;
  color: #000;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 17px;
}
.badge{
  position: absolute;
    top: -7px;
    right: 2px;
    font-size: 13px !important;
    background-color: #fff !important;
    color: #000 !important;
    padding: 2px 5px !important;
}
.navbar a:hover {
  background: #f1f1f1;
  color: black;
}

.navbar a.active {
  background-color: #04AA6D;
  color: white;
}

.main {
  padding: 16px;
  margin-bottom: 30px;
}
.loader {
  margin-top: 40px !important;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #f30d77;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: auto;
    margin-top: 10% !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.center {
  text-align: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: space-between;
  margin-top: 20px;
    
}

.container {
    margin-bottom: 100px !important;
}
.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}
#app_logo{
  width: 100px;
}

.wrapper_menu{
    border-top-left-radius: 15px;
      border-top-right-radius: 15px;
}

#view_menu, #search_products {
      position: absolute;
        top: 13px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: top 500ms ease-out;
        background: transparent !important;
        padding: 0px !important;
        font-size: 20px !important;

}

#search_products{
    margin-right: 20px;
}

.appMenuPopup{
  padding: 0px;
}
.appMenuPopup li {
  list-style: none;
  text-align: center;
  margin: auto !important;
    background-color: #f30d77;
    border-radius: 10px;
    margin-bottom: 10px !important;
    width: 100%;
}
.appMenuPopup a{
  text-decoration: none;
  margin: 0;
  padding: 15px 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  display: block;
}
/* Add this CSS to your existing styles or create a new CSS file */

.loader-skeleton {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skeleton-card {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
  /* Add fadeIn animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.skeleton-image {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  margin-bottom: 15px;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.skeleton-title {
  margin-top: 15px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  animation: fadeIn 0.8s ease-in-out;
  /* Add fadeIn animation */
}

.skeleton-text {
  margin-top: 10px;
  height: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  animation: fadeIn 0.8s ease-in-out;
  /* Add fadeIn animation */
}

.skeleton-button {
  margin-top: 10px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 5px;
  animation: fadeIn 0.8s ease-in-out;
  /* Add fadeIn animation */
}

.cart_icon svg{
  color: #fff;
  font-size: 20px !important;
}
.cart_icon {
  text-align: center;
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: top 500ms ease-out;
}

.wrapper_menu{
  position: fixed;
  bottom: 0px;
}
.wrapper {
  width: 100%;
  z-index: 9999;
  margin: 0 auto;
  bottom: 0px;
  left: 0px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  background: linear-gradient(to bottom right, #272632, #4b4c61);
  box-shadow: 0 8px 32px 2px rgba(0, 0, 0, 0.3);
}


*[class^=icon-] {
  height: 24px;
  width: 24px;
}

.nav {
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-flow: row nowrap;
}

.nav-item a{
    width: 100%;
    display: inline-block;
    height: 3.5rem;
}
.nav-item {
  flex: 1;
  position: relative;
  cursor: pointer;
  height: 3.5rem;
}

.nav-item .hump {
  top: -24px;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 500ms ease-out;
}

.nav-item .hump svg,
.nav-item .hump path {
  fill: rgba(255, 255, 255, 1);
}

.nav-item .title {
  color: #bbb;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -150%;
  font-size: 12px;
  text-align: center;
  transition: bottom 500ms ease-out;
}

.nav-item *[class^=icon-] {
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: top 500ms ease-out;
}

.nav-item *[class^=icon-] svg,
.nav-item *[class^=icon-] path {
  fill: #fff;
}

.nav-item.active .hump {
  transform: scaleY(1);
}

.nav-item.active .title {
  bottom: 16px;
}

.nav-item.active *[class^=icon-] {
  position: absolute;
  top: -8px;
}

.nav-item.active *[class^=icon-] svg,
.nav-item.active *[class^=icon-] path {
  fill: #fff;
}

.products_list .card-img-top {
  display: inline-block !important;
  width: 49% !important;
  float: left;
}

.products_list .card-body{
  display: inline-block !important;
  width: 49% !important;
  float: right;
}

.products_list .card-footer {
  display: inline-block !important;
  width: 100%;
    display: block;
    float: inline-start;
}

.products_list .card {
  display: inline-block !important;
}

.card-title {
 
  text-align: left;
}

.discount-badge{
  color:#fff !important;
  padding: 5px !important;
  font-size: 18px !important;
}

.card-text{
      font-size: 22px;
      text-align: center;
}

ins{
  text-decoration: none;
  color: #e30101;
}

.woocommerce-Price-amount{
  font-weight: bold;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  height: 100vh;
}

.modal.show .modal-dialog {
  margin: 0px;
}
.modal-open{
  overflow: hidden !important;

}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}
.modal-content, .modal-dialog, .modal-body,.modal-header {
  border: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.swipe {
  position: absolute;
  z-index: 999;
  right: 50px;
}

.path {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgba(149, 147, 241, 0.5);
  border-radius: 40px;
  top: 28px;
  left: 78px;
  visibility: hidden;
  margin-top: 170px;
}

.hand-icon {
  position: relative;
  background-image: url('assets/hand.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin-top: 170px;
  transform-origin: 52% 62%;
}

@keyframes swipe-hand {
  25% {
    transform: translate(20px) rotate(30deg);
  }

  50% {
    transform: translate(-20px) rotate(-15deg);
  }

  100% {
    transform: translate(0px) rotate(0);
  }
}

@keyframes swipe-dot {
  12% {
    visibility: visible;
    width: 40px;
  }

  25% {
    visibility: visible;
    transform: translate(-65px);
    width: 20px;
  }

  26% {
    visibility: hidden;
  }
}